export async function fetchWithRetry(
  url: string,
  init: RequestInit,
  retries: number
): Promise<Response> {
  try {
    const response: Response = await fetch(url, init)
    if ((response.status !== 403 && response.status < 500) || retries <= 0) {
      return response
    }
  } catch (error) {
    if (retries <= 0) {
      throw error
    }
    return fetchWithRetry(url, init, retries - 1)
  }
  return fetchWithRetry(url, init, retries - 1)
}
