import { AppsAdapter } from './apps.adapter'

declare global {
  interface Window {
    OneTrust: OneTrust
  }
}
export interface OneTrust {
  ToggleInfoDisplay(): void
  RejectAll(): void
  AllowAll(): void
  UpdateConsent(groupIdType: string, bitValue: string): void
  updateConsentFromCookies(encodedIabString: string): Promise<void>
  SetAlertBoxClosed(): void
}

export class ConsentProviderAdapter {
  constructor(private readonly apps: AppsAdapter) {}

  togglePreferenceCenter(): void {
    if (this.isAppContext()) {
      this.apps.openPreferenceCenter()
    } else {
      window.OneTrust.ToggleInfoDisplay()
    }
  }

  resurfaceBanner(): void {
    if (this.isAppContext()) {
      this.apps.resurfaceBanner()
    } else {
      this.togglePreferenceCenter()
    }
  }

  rejectGeneralVendorConsent(generalVendorId: string): void {
    if (this.isAppContext()) {
      this.apps.optOutGeneralVendor(generalVendorId)
    } else {
      window.OneTrust.UpdateConsent('General Vendor', `${generalVendorId}:0`)
    }
  }

  rejectAll(): void {
    if (this.isAppContext()) {
      this.apps.optOutAll()
    } else {
      window.OneTrust.RejectAll()
    }
  }

  allowAll() {
    if (this.isAppContext()) {
      this.apps.acceptConsents()
    } else {
      window.OneTrust.AllowAll()
    }
  }

  async updateIabConsent(encodedIabString: string): Promise<void> {
    if (this.isAppContext()) {
      /*                                                                  */
      /*                            */
      window.OneTrust.SetAlertBoxClosed()
    }
    await window.OneTrust.updateConsentFromCookies(encodedIabString)
  }

  private isAppContext(): boolean {
    return this.apps.runningInApp()
  }
}
