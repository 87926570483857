import type { TCData } from '@iabtechlabtcf/cmpapi'

export type TcfApiFn = (
  command: 'getInAppTCData',
  version: 2,
  callback: (data: TCData, success: boolean) => void,
  ...params: [vendorIdsFilter: number[]]
) => void

declare global {
  interface Window {
    __tcfapi?: TcfApiFn
  }
}

const CHECK_INTERVAL_MS = 10
export const RETRY_GET_TCF_API_TIMEOUT = 1500

export class TcfApiWrapper {
  private lazyTcfApiPollPromise: Promise<TcfApiFn> | undefined

  async tcfApi(): Promise<TcfApiFn> {
    if (window.__tcfapi) {
      return window.__tcfapi
    }
    if (this.lazyTcfApiPollPromise) {
      return this.lazyTcfApiPollPromise
    }
    /*                                                         */
    this.lazyTcfApiPollPromise = this.pollForTcfApi()
    return this.lazyTcfApiPollPromise
  }

  private pollForTcfApi(): Promise<TcfApiFn> {
    return new Promise((resolve, reject) => {
      let elapsedTime = 0

      function checkEveryTenMsForTcfApi() {
        setTimeout(() => {
          if (window.__tcfapi) {
            return resolve(window.__tcfapi)
          }

          elapsedTime += CHECK_INTERVAL_MS
          if (elapsedTime >= RETRY_GET_TCF_API_TIMEOUT) {
            return reject(
              new Error(
                `__tcfapi is not available after ${RETRY_GET_TCF_API_TIMEOUT}ms`
              )
            )
          }
          checkEveryTenMsForTcfApi()
        }, CHECK_INTERVAL_MS)
      }

      checkEveryTenMsForTcfApi()
    })
  }
}
