/*                                                   */
import { loggingService as loggingService } from './logger'

type LastCallSuccess<Args, ResultType> = {
  args: Args
  result: ResultType
  isPromise: boolean
  success: true
}
type NoLastCall = { success: false }
type LastCall<Args, ResultType> = LastCallSuccess<Args, ResultType> | NoLastCall

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function memoizeLastSuccess<This, Args extends any[], Return>(
  localStorageKey: `${string}.${string}.${string}`,
  targetFn: (this: This, ...args: Args) => Return
) {
  function replacementMethod(this: This, ...args: Args): Return {
    try {
      const lastCall = lastCallFromLocalStorage()

      if (lastCall.success && compareArgs(args, lastCall.args)) {
        /*                                                    */
        loggingService.sample(
          `Memoized call from localStorage ${localStorageKey}. Sample rate: ${0.01}, args: ${JSON.stringify(args)}, result: ${JSON.stringify(lastCall.result)}, isPromise: ${lastCall.isPromise}`,
          0.01
        )

        return (
          lastCall.isPromise
            ? Promise.resolve(lastCall.result)
            : lastCall.result
        ) as Return
      }
    } catch (e) {
      /*                                                   */
      loggingService.logError(e)
    }
    try {
      /*                     */
      const result = targetFn.apply(this, args)

      if (result instanceof Promise) {
        return result
          .then((awaitedRes: Awaited<Return>) => {
            cacheLastCallInLocalStorage(args, awaitedRes, true)
            return awaitedRes
          })
          .catch((err: any) => {
            resetLastCall()
            throw err
          }) as Return
      }

      cacheLastCallInLocalStorage(args, result as Awaited<Return>, false)
      return result
    } catch (error) {
      resetLastCall()
      throw error
    }
  }
  return replacementMethod

  function lastCallFromLocalStorage(): LastCall<Args, Awaited<Return>> {
    const entry: string | null = null
    try {
      const entry = localStorage.getItem(localStorageKey)
      if (entry == null) {
        return { success: false }
      }

      const parse = JSON.parse(entry)
      if (!checkParse(parse)) {
        return { success: false }
      }
      return { ...parse, success: true }
    } catch (e) {
      loggingService.logError(
        new Error(
          `Could not parse localStorage ${localStorageKey}: ${entry} ${e}`
        )
      )
      return { success: false }
    }
  }

  function checkParse(
    parse: any
  ): parse is LastCallSuccess<Args, Awaited<Return>> {
    /*                                                    */
    return (
      parse['args'] instanceof Array && typeof parse['isPromise'] === 'boolean'
    )
  }

  function resetLastCall() {
    try {
      localStorage.removeItem(localStorageKey)
    } catch (e) {
      loggingService.logError(e)
    }
  }

  function cacheLastCallInLocalStorage(
    args: Args,
    result: Awaited<Return>,
    isPromise: boolean
  ) {
    try {
      localStorage.setItem(
        localStorageKey,
        JSON.stringify({ args, result, isPromise })
      )
    } catch (e) {
      loggingService.logError(e)
    }
  }

  function compareArgs(args: Args, args2: Args): boolean {
    try {
      return JSON.stringify(args) === JSON.stringify(args2)
    } catch (e) {
      loggingService.logError(e)
      return false
    }
  }
}
