import { eventQBus } from '@otto-ec/global-resources/event-q-bus'

export const CMP_MODULE_NAME = 'eprivacy.privacy.cmp'

export function emitModuleLoadedEvents() {
  eventQBus.emit(`${CMP_MODULE_NAME}-loaded`)
  const moduleLoadedEventName = 'assets.module.loaded'
  eventQBus.emit(moduleLoadedEventName, {
    moduleId: CMP_MODULE_NAME,
  })
}

export function emitModuleInitializedEvent() {
  eventQBus.emitModuleInitialized(CMP_MODULE_NAME)
}
