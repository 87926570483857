import type { TCData } from '@iabtechlabtcf/cmpapi'
import { CmpTcfApi } from '../../adapter/tcf-api/tcf-api.type'
import { Vendor, vendors } from './vendor.enum'
import { initDefaultTcfVendorsConsentsMap } from './init-default-tcf-vendors-consents-map'
import { LoggingService } from '../../utils/logging-service'
import { TcfApiWrapper } from '../../adapter/tcf-api/tcf-api.wrapper'

export class TcfNoVendorListService implements CmpTcfApi {
  constructor(
    private readonly logger: LoggingService,
    private readonly tcfApiWrapper: TcfApiWrapper
  ) {}

  async readVendorsConsents(): Promise<Map<Vendor, boolean>> {
    const defaultVendorsConsents = initDefaultTcfVendorsConsentsMap()
    try {
      const tcfapi = await this.tcfApiWrapper.tcfApi()

      return new Promise<Map<Vendor, boolean>>((resolve) => {
        tcfapi(
          'getInAppTCData',
          2,
          (tcData, success) => {
            if (!success) {
              this.logger.logError(
                new Error(`__tcfapi getInAppTCData command not successful`)
              )
              return resolve(defaultVendorsConsents)
            }

            const consents = tcData.vendor.consents

            if (typeof consents === 'string') {
              return this.extractStringVendorsConsent(
                consents,
                resolve,
                defaultVendorsConsents
              )
            }
            return this.extractBooleanVectorConsents(
              consents,
              defaultVendorsConsents,
              resolve
            )
          },
          vendors
        )
      })
    } catch (e) {
      this.logger.logError(
        new Error(
          `Error readVendorsConsents. Returning default consent. Cause: ${e}`,
          { cause: e }
        )
      )
      return defaultVendorsConsents
    }
  }

  private extractBooleanVectorConsents(
    consents: Omit<TCData['vendor']['consents'], string>,
    vendorsConsent: Map<Vendor, boolean>,
    resolve: {
      (value: Map<Vendor, boolean> | PromiseLike<Map<Vendor, boolean>>): void
    }
  ) {
    const vendorIds = Object.entries(consents)
      .filter(([key]) => Vendor[Number(key)])
      .map(([key, value]) => [Number(key), value] as [Vendor, boolean])
    for (const [vendor, value] of vendorIds) {
      vendorsConsent.set(vendor, value)
    }
    return resolve(vendorsConsent)
  }

  /*                                                                                           */
  private extractStringVendorsConsent(
    consents: string,
    resolve: {
      (value: Map<Vendor, boolean> | PromiseLike<Map<Vendor, boolean>>): void
    },
    vendorsConsent: Map<Vendor, boolean>
  ) {
    for (const vendorId of vendors.toSorted((a, b) => a - b)) {
      if (consents.length < vendorId) {
        return resolve(vendorsConsent)
      }
      vendorsConsent.set(vendorId, consents[vendorId - 1] === '1')
    }
    return resolve(vendorsConsent)
  }
}
