import { ConsentConfig } from './consent-config'
import CONSENT_CONFIG_URL from '/consent-configuration/v1.json?url'

export class ConsentConfigLoader {
  private config?: ConsentConfig[]

  async loadConfig(): Promise<ConsentConfig[]> {
    if (!this.config) {
      const response = await fetch(CONSENT_CONFIG_URL)
      this.config = (await response.json()) as ConsentConfig[]
    }
    return this.config
  }
}
