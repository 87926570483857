import { ReceiptArchiver } from './receipt-archiver'
import { LoggingService } from '../utils/logging-service'
import { consentChanged } from '@otto-ec/eprivacy-cmp'
import { Cmp } from './cmp.type'
import { memoizeLastSuccess } from '../utils/memoize-last-success'
import { Cookies } from '../adapter/cookies'
import { ConsentProviderAdapter } from '../adapter/consent-provider.adapter'
import { eventQBus } from '@otto-ec/global-resources/event-q-bus'
import { CmpTcfApi } from '../adapter/tcf-api/tcf-api.type'
import { ConsentConfigService } from './consent-config/consent-config.service'

export class CmpImpl implements Cmp {
  readonly #receiptArchiver: ReceiptArchiver

  readonly #loggingService: LoggingService
  readonly #cookies: Cookies
  readonly #consentProvider: ConsentProviderAdapter
  readonly #tcfApi: CmpTcfApi
  readonly #consentConfigService: ConsentConfigService

  constructor(
    consentProvider: ConsentProviderAdapter,
    tcModelService: CmpTcfApi,
    receiptArchiver: ReceiptArchiver,
    cookies: Cookies,
    loggingService: LoggingService,
    consentConfigService: ConsentConfigService
  ) {
    this.#consentProvider = consentProvider
    this.#tcfApi = tcModelService
    this.#cookies = cookies
    this.#receiptArchiver = receiptArchiver
    this.#loggingService = loggingService
    this.#consentConfigService = consentConfigService
  }

  public togglePreferenceCenter(): void {
    this.#consentProvider.togglePreferenceCenter()
  }

  public resurfaceBanner(): void {
    this.#consentProvider.resurfaceBanner()
  }

  public rejectAll(): void {
    this.#consentProvider.rejectAll()
  }

  /*                 */
  public allowAll(): void {
    this.#consentProvider.allowAll()
  }

  /**
 *
 *
 *
 */
  public rejectGeneralVendorConsent(generalVendorId: string): void {
    this.#consentProvider.rejectGeneralVendorConsent(generalVendorId)
  }

  /**
 *
 */
  public getIabConsentString(): undefined | string {
    return this.#cookies.getIabConsentCookieValue()
  }

  public emitConsentChangeEvent(): void {
    void consentChanged.emit()

    /*                                                                    */
    /*                          */
    eventQBus.emit('eprivacy.cmp.consentChanged')
  }

  public async processAppConsentV2(
    consentId: string,
    userConsents: {
      [key: string]: number | string
    } | null = {},
    iabString: string
  ): Promise<boolean> {
    if (!consentId) {
      this.#loggingService.logError(
        new Error('consentId is required to process app consent')
      )
      return false
    }
    if (userConsents === null || Object.keys(userConsents).length === 0) {
      this.#loggingService.logMessage(
        `processAppConsentV2: userConsents is empty: ${JSON.stringify(userConsents)}`
      )
    }
    /*                                         */
    /*                                                                                    */
    await this.#consentProvider.updateIabConsent(iabString)
    this.#cookies.setIabConsentCookieValue(iabString)

    const normalizedUserConsents = this.#normalizeUserConsents(userConsents)
    this.#setAppConsentCookie(normalizedUserConsents, consentId)

    this.emitConsentChangeEvent()
    try {
      await this.#memoizedAppsArchiveReceipt(
        consentId,
        normalizedUserConsents,
        iabString
      )
    } catch (error) {
      this.#loggingService.logError(error)
      return false
    }
    return true
  }

  /**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
  readonly #memoizedAppsArchiveReceipt = memoizeLastSuccess(
    'eprivacy.cmp.app-last-archive-receipt-call',
    async (
      consentId,
      /*                                                      */
      /*                                                         */
      userConsents: Record<string, number> | null,
      /*                                                      */
      /*                                                         */
      iabString: string
    ) => {
      await this.archiveReceipt(consentId, 'OneTrust-CB-App')
      return true
    }
  )

  /**
 *
 *
 *
 */
  public readVendorsConsents(): Promise<Map<number, boolean>> {
    return this.#tcfApi.readVendorsConsents(
      this.#cookies.getIabConsentCookieValue()
    )
  }

  public async getAppGoogleTrackingConsent(): Promise<boolean> {
    return this.evaluateConsentConfiguration('APP_GOOGLE')
  }

  /**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */

  public async getAdjustTrackingConsent(): Promise<boolean> {
    return this.evaluateConsentConfiguration('ADJUST')
  }

  private async evaluateConsentConfiguration(
    configId: string
  ): Promise<boolean> {
    const tcfConsents = await this.readVendorsConsents()
    const gvConsents = this.getConsentsForAllGVs()

    return this.#consentConfigService.evaluateConsent(
      configId,
      tcfConsents,
      gvConsents
    )
  }

  public async archiveReceipt(
    consentId: string | null,
    source: string | null
  ): Promise<boolean> {
    if (consentId === null || source === null) {
      return Promise.resolve(false)
    }
    try {
      return await this.#receiptArchiver.archiveReceipt(consentId, source)
    } catch (e) {
      this.#loggingService.logError(e)
      throw e
    }
  }

  public getConsentForGV(key: string): boolean {
    const { genVendors, groups } = this.#loadAndParseOptanonConsentIntern()
    return genVendors[key] === '1' || groups[key] === '1'
  }

  public getConsentsForAllGVs(): { [key: string]: string } {
    const parsedCookie = this.#loadAndParseOptanonConsentIntern()
    return { ...parsedCookie.genVendors, ...parsedCookie.groups }
  }

  /**
 *
 *
 *
 */
  public getOttoOwnPurposesConsent(): boolean {
    return this.getConsentForGV('OTTOT') || this.getConsentForGV('OTTON')
  }

  #normalizeUserConsents(
    userConsents: { [key: string]: number | string } | null
  ): {
    [p: string]: number
  } | null {
    if (userConsents == null || Object.keys(userConsents).length === 0) {
      return null
    }
    const result: Record<string, number> = {}

    for (const key in userConsents) {
      const numValue = Number(userConsents[key])
      if (!isNaN(numValue)) {
        result[key] = numValue
      } else {
        const error = new Error(
          `Value for ${key} is not a valid number or numeric string.`
        )
        this.#loggingService.logError(error)
        throw error
      }
    }

    return { ...userConsents, ...result } as Record<string, number>
  }

  #setAppConsentCookie(
    userConsents: { [p: string]: number } | null,
    consentId: string
  ): void {
    if (userConsents == null) {
      return
    }

    const genVendorsKeyValueString = Object.entries(userConsents).map((entry) =>
      entry.join(':')
    )
    const formattedConsents =
      'genVendors=' + encodeURIComponent(genVendorsKeyValueString.join(','))

    const cookieValue = `${formattedConsents}&consentId=${consentId}`

    this.#cookies.setGenericConsentCookie(cookieValue)
  }
  #loadAndParseOptanonConsentIntern(): {
    genVendors: Record<string, string>
    groups: Record<string, string>
  } {
    const optanonConsentCookie = this.#cookies.getGenericConsentCookie()

    if (!optanonConsentCookie) {
      return { genVendors: {}, groups: {} }
    }

    const decodedCookieValue = decodeURIComponent(optanonConsentCookie)

    const matchGenVendors = decodedCookieValue.match(/genVendors=([^&]*)/)
    const matchGroups = decodedCookieValue.match(/groups=([^&]*)/)

    const genVendors = this.#extractVendors(matchGenVendors?.[1])
    const groups = this.#extractVendors(matchGroups?.[1])

    return { genVendors, groups }
  }

  #extractVendors(genVendorCookieString?: string): { [p: string]: string } {
    if (!genVendorCookieString) {
      return {}
    }
    const keyValuePairsGenVendors = genVendorCookieString
      .split(',')
      .filter((e) => e !== '')
      .map((pair) => pair.split(':'))
    return keyValuePairsGenVendors.reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value,
      }),
      {}
    )
  }
}
