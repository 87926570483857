import {
  emitModuleInitializedEvent,
  emitModuleLoadedEvents,
} from './module-initialisation-events'
import { Cmp } from '../cmp.type'

declare global {
  interface Window {
    cmp: Cmp
  }
}

export function initGlobal(cmp: Cmp) {
  window.cmp = cmp
  emitModuleInitializedEvent()
}
emitModuleLoadedEvents()
