import { Vendor } from '../iab-tcf/vendor.enum'
import { ConsentConfigLoader } from './consent-config-loader'
import { ConsentCondition, ConsentConfig } from './consent-config'

type GVConsentsRecord = Record<string, '0' | '1' | string>
type TCFConsentMap = Map<Vendor, boolean>

export class ConsentConfigService {
  constructor(private readonly configLoader: ConsentConfigLoader) {}
  async evaluateConsent(
    configId: string,
    tcfConsents: TCFConsentMap,
    gvConsents: GVConsentsRecord
  ): Promise<boolean> {
    const consentConfigs = await this.configLoader.loadConfig()

    if (!consentConfigs) {
      throw new Error(`Could not load consent config.`)
    }
    return this.evaluateConsentIntern(
      configId,
      tcfConsents,
      gvConsents,
      consentConfigs
    )
  }

  private evaluateConsentIntern(
    id: string,
    tcfConsents: TCFConsentMap,
    gvConsent: GVConsentsRecord,
    consentConfigs: ConsentConfig[]
  ): boolean {
    const config = consentConfigs?.find((config) => config.id === id)

    if (!config) {
      throw new Error(`Configuration with id ${id} not found.`)
    }

    return this.evaluateTrackingConfigConditions(
      config,
      tcfConsents,
      gvConsent,
      consentConfigs
    )
  }

  private evaluateTrackingConfigConditions(
    config: ConsentConfig,
    tcfConsents: TCFConsentMap,
    gvConsents: GVConsentsRecord,
    consentConfigs: ConsentConfig[]
  ): boolean {
    const { operator, conditions } = config
    this.assertOperator(operator)
    let result = false
    for (const condition of conditions) {
      result = this.handleConditionsVendorType(
        condition,
        gvConsents,
        tcfConsents,
        consentConfigs
      )
      /*                                                                           */
      if (operator === 'OR' && result) {
        return true
      }
      /*                                                                              */
      if (operator === 'AND' && !result) {
        return false
      }
    }
    return result
  }

  private handleConditionsVendorType(
    condition: ConsentCondition,
    gvConsents: GVConsentsRecord,
    tcfConsents: TCFConsentMap,
    consentConfig: ConsentConfig[]
  ) {
    switch (condition.type) {
      case 'GV':
        return this.getConsentForGV(condition.vendorId, gvConsents)
      case 'TCF2v2': {
        return tcfConsents.get(Number(condition.vendorId)) ?? false
      }
      case 'CONFIG': {
        return this.evaluateConsentIntern(
          condition.vendorId,
          tcfConsents,
          gvConsents,
          consentConfig
        )
      }
      default:
        throw new Error(`Unknown vendor type: ${condition.type}`)
    }
  }

  private getConsentForGV(
    vendorId: string,
    gvVendors: GVConsentsRecord
  ): boolean {
    return gvVendors[vendorId] === '1'
  }

  private assertOperator(
    operator: string
  ): operator is ConsentConfig['operator'] {
    switch (operator) {
      case 'OR':
        return true
      case 'AND':
        return true
      default:
        throw new Error(`Unknown operator type: ${operator}`)
    }
  }
}
