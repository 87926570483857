import { fetchWithRetry } from '../utils/fetch-with-retry'
import { toggle } from '@otto-ec/global-resources/toggle'
import { Logger } from '../utils/logger'

export class ReceiptArchiver {
  private static readonly CONSENT_NOTIFICATION_CONTEXT: string =
    '/user-push-consent-notification'

  constructor(private readonly logger: Logger) {}

  private static readonly MAX_RETRIES = 2

  private readonly _32BitIntMultiplier = 4294967296
  private readonly _32BitIntMaxValue = 2147483648

  private static readonly TRACING_TOGGLE_NAME =
    'cb_consent_receipt_trace_enabled'

  private readonly isTracingEnabled = toggle.get(
    ReceiptArchiver.TRACING_TOGGLE_NAME,
    false
  )

  public async archiveReceipt(
    consent: string,
    source: string
  ): Promise<boolean> {
    if (!consent || !source) {
      throw new Error('Consent and source must be provided.')
    }
    const traceId =
      Math.floor(Math.random() * this._32BitIntMultiplier) -
      this._32BitIntMaxValue

    const payload = {
      consent: consent,
      source: source,
      traceId: traceId,
    }
    const response = await fetchWithRetry(
      ReceiptArchiver.CONSENT_NOTIFICATION_CONTEXT,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      },
      ReceiptArchiver.MAX_RETRIES
    )
    if (!response.ok) {
      throw new Error(
        'Could not archive consent receipt: ' +
          response.status +
          'for consent: ' +
          consent +
          ' and source: ' +
          source
      )
    }
    if (this.isTracingEnabled) {
      this.logger.logMessage(
        'Consent receipt archived with traceId: ' + traceId
      )
    }
    return true
  }
}
